const hostPath = 'https://message-flow.keldysh.io';

export const environment = {
  hostPath: hostPath,
  apiPath: hostPath + '/api/landing-page-service',
  firebaseConfig: {
    apiKey: "AIzaSyCFAxv-kmfzj4Evbw5u5XPMlDaP7HfRuBw",
    authDomain: "keldysh-messageflow.firebaseapp.com",
    projectId: "keldysh-messageflow",
    storageBucket: "keldysh-messageflow.appspot.com",
    messagingSenderId: "711047575475",
    appId: "1:711047575475:web:1e7d56f67159e95efb3dc6",
    measurementId: "G-6R8V9X7VGP"
  },
  production: true
};
